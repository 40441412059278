// import { useState } from "react";
import { useState } from "react";
import { introDesktop } from "../Images";
import sectionStyle from "./sectionStyle.module.scss";
// import Question from "../Question";
// import Hints from "../Hints";
import intro from "../../assets/images/intro/dedewIntro2.mp4";
import { useEffect } from "react";
import { motion } from "framer-motion";
function Section() {
  // console.log(Quiz);
  // const [quizOpened, setQuizOpened] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  });
  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  return (
    <div className={sectionStyle["section"]} id="section">
      <header className={sectionStyle["header"]}>
        {loader ? (
          <section className={sectionStyle["intro-container"]}>
            <div className={sectionStyle["overlay"]}></div>
            <motion.video
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 1 }}
              src={intro}
              type="video/mp4"
              autoPlay
              loop
              muted
              controlsList="nodownload" 
              controls={false}
            ></motion.video>
          </section>
        ) : (
          <>
            <motion.img
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 1 }}
              src={introDesktop}
              
              alt=""
            />
            {/* <div className={sectionStyle["btn-container"]}>
              <button className={sectionStyle["header-button"]}>
                MINT CALENDAR
              </button>
              <button
                className={sectionStyle["header-button"]}
                onClick={() => setQuizOpened(!quizOpened)}
              >
                QUIZ
              </button>
            </div> */}
          </>
        )}
      </header>
    </div>
  );
}

export default Section;

// {/* {quizOpened ? (
//           <div>
//             <div className="quiz-container">
//               <div className="quiz_inner">
//                 <div className="answer-count">
//                   <h4>Answer Count: 2</h4>
//                 </div>

//                 <div className="quiz-main">
//                   <div className="container">
//                     <Question
//                     // question={question}
//                     // inputEvent={getUserAnswer}
//                     // submitEvent={checkAnswer}
//                     // loading={loading}
//                     />
//                     <Hints
//                     // userCount={userCount}
//                     // question={question}
//                     // user={user}
//                     />
//                   </div>
//                 </div>

//                 <div className="hint-count">
//                   <button
//                     className="quiz-button"
//                     onClick={() => setQuizOpened(false)}
//                   >
//                     CLOSE
//                   </button>
//                   <h4>Hint Count: 3</h4>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div style={{ visibility: "hidden" }}></div>
//         )} */}
