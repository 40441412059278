// import Hi from "./Hi";
// import "./Faq.scss";
import Carousel from "react-bootstrap/Carousel";
import learnStyle from "./learnStyle.module.scss";

const faq = [
  {
    id: 1,
    question: "DEDEW NEDİR?",
    answer: `DEDEW COMPANY, modern çalışma türü olan "remote" çalışma sisteminin üzerine mimarisini kurmuş ve geliştirmiştir. Sahibi olduğu bağlantılara merkeziyetsiz yazılımcılarla birlikte yazılım hizmeti veren bir teknoloji  şirketidir.
    DEDEW COMMUNITY olarak, DEDEW mimarisini binlerce yazılımcıya sunmayı hedeflemekteyiz ve beraber üretmek amacıyla yola çıkmış bulunmaktayız.
    `,
  },
  {
    id: 2,
    question: "NEDEN VE NASIL MERKEZİYETSİZ?",
    answer: "Zaman kavramını kontrol edemeyiz ve geri kazanamayız. Sadece yönetebiliriz. Bir işi belki de sadece 3 saatlik bir zaman diliminde yapabiliyorken günümüzün ortalama 8 saatlik dilimini neden bir işyerinde harcayalım ki? DEDEW, sizlerden zamanınızı kendiniz yönetebilmenizi istiyor. Yazılımcıların kendi çalışma sistematiğini oluşturan bir mimariye sahip olan DEDEW, yazılımcıların ne kadar ve ne zaman çalıştığına değil; işlerini zamanında teslim edip etmediği ile ilgileniyor. 	Kısaca sizler şirketler için çalışmayı bırakın DEDEW sizin için çalışsın.",
    
  },
  {
    id: 3,
    question: "DEDEW’İN FARKI NEDİR?",
    answer: `Adımlarımızı dünya ile aynı anda atıyor olabilmek bizim misyonumuz, dünyaya adımları attırabilmek bizim vizyonumuz... 
    ⦁	Üyelerimiz çalışma saatlerini özgürce planlayabilme imkanına sahiptir. Tek sorumlulukları kararlaştırılmış olan son teslim tarihidir.
    ⦁	DEDEW topluluğu, anonim olma ayrıcalığı sağlar ve üyelerini sadece iş kimlikleriyle değerlendirir. Kişinin sadece mesleki yetkinliğini aktarması onun işe kabulünü mümkün kılar.
    ⦁	Hizmet bedelleri DEDEW tarafından sunulan ödeme sistemleri ile yazılımcıya aktarılır.
    ⦁	DEDEW topluluğu, yazılım alanında kendini geliştirmek isteyen kullanıcılarına ortam hazırlar.
    ⦁	İşin yapısını ve iletişim sürecini DEDEW üstlenir. Yazılımcı herhangi bir dil bariyerine maruz kalmaksızın operasyonu çözümler. 
    ⦁	DEDEW topluluğu remote çalışma sistematiğine sahiptir.
    `,
  },
  {
    id: 4,
    question: "DEDEW TOPLULUĞUNDA YAŞAM NASIL?",
    answer: `⦁	Topluluğumuzdaki yazılımcıların seviyelerinin doğrulanmasını sağlıyoruz.
    ⦁	İstediğimiz zaman aralığında çalışıyoruz ve zamanımızı kendimiz yönettiğimiz için belirlediğimiz seviyede gelir elde ediyoruz.
    ⦁	Kendimizi pazarlama zorunluluğunda kalmıyor, sadece yetkinliklerimizi gösteriyoruz.
    ⦁	Yazılımcının dili olan kodlamayı kullanarak dil bariyerine maruz kalmıyoruz.
    ⦁	Operasyon ve müşteri ile vakit kaybetmeden kod yazmaya odaklanabiliyoruz.
    ⦁	Sadece topluluk içinde aktif olarak gelir elde edebiliyoruz.
    ⦁	Sahibi olacağımız NFT'lerden pasif gelir elde ediyoruz.
    ⦁	Davetler, etkinlikler, fuarlar, konserler, paneller gibi etkinliklerde sosyal hayatta bir araya geliyoruz.
    ⦁	Sektörel gelişmeleri yakından takip ediyoruz ve yeni bilgiler öğrenerek sahip olduğumuz bilgileri geliştiriyoruz.
    ⦁	Yeni bağlantılar ediniyoruz 
    ⦁	Toplulukta bulunan kişiler ve DEDEW ile projelerimizi hayata geçiriyoruz.
    ⦁	Sosyal kişiliklerimizden bağımsız olarak, sadece yazılımsal yetkinliklerimiz ve iş kimliklerimizle oluşturulmuş anonim profillere sahibiz.
    ⦁	Teslim sürecine bağlı olarak çalışıyoruz
    ⦁	Remote, outsource ve freelance kavramlarını bir arada tutulan operasyona sahibiz. 
    ⦁	Uzmanlık ve seviye fark etmeksizin bütün alanlardaki yazılımcılarımızı bir arada tutuyoruz.
    
    
    ⦁	Remote çalışma sistematiğinden yaralanarak yer ve mekan farketmeksizin hizmetlerimizi sunabiliyoruz.
    ⦁	Verdiğimiz hizmet bedelleri DEDEW tarafından karşılanmaktadır.
    `,
  },
  {
    id: 5,
    question: "HİZMETLERİMİZİ NASIL SUNACAĞIZ?",
    answer: `⦁	Sistemimizde, anonim olmamızı sağlayacak dijital kimliklerimizi DEDEW'e ait NFT koleksiyonuyla oluşturacağız. Topluluktaki aktifliğiniz analiz edilecek ve buna bağlı olarak pasif gelir elde edebileceksiniz.`,
  },
  {
    id: 6,
    question: "TOPLULUĞUN BİR PARÇASI NASIL OLURUZ ?",
    answer: `⦁	Topluluğa katılımı sağlayacak olan dijital kimliklerimiz, sizlerin belirlediği fiyat aralıklarına bağlı olarak satışa çıkacaktır ve toplulukta bulunmak isteyen kişi sayısına bağlı olarak satış adedi belirlenecektir. Bunun için sosyal medya sitelerimizdeki anketlere katılmayı unutmayın.


    ⦁	Satış döneminde sizlere sunulacak olan bir diğer özellik ise, topluluğa katılmak için elde edeceğiniz NFT'leri sahibi olduğunuz yazılımsal bilgiye bağlı olarak indirim kazanabilirsiniz.
    `,
  },
  {
    id: 7,
    question: "SATIŞ SÜRECİ?",
    answer: `⦁	Dijital kimliklerimizin satış süreci, gerekli takipçi sayısına erişildikten sonra başlıyor ve duyuruluyor olacaktır. Topluluğumuza şimdiden Discord kanalımızdan ücretsiz katılabilirsiniz. Örnek kimliklerimizi görmek için takım arkadaşlarımıza bakınız.`,
  },
  
];
function Learn() {
  return (
    <section className={learnStyle.section} id="learn">
      <header className={learnStyle.header}>
        <h2>LEARN</h2>
      </header>
      <Carousel className={learnStyle.container} interval={null} >
        {faq.map((item) => (
          <Carousel.Item key={item.id} className={learnStyle.item}>
            <div className={learnStyle.itemContainer}>
              <div className={learnStyle.question}>
                <h2>{item.question}</h2>
              </div>
              <div className={learnStyle.answer}>
                <h4>{item.answer}</h4>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}
// }

export default Learn;
