import { v4 as uuidv4 } from "uuid";
import { imgArt1, imgArt2, imgArt3, imgArt4, imgArt5 } from "../../Images";
import Card from "./Card";

const cards = [
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt1} social={"imgArt1"} name="Fiet" role="Developer" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt2} social={"imgArt2"} name="Amt" role="Developer" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt3} social={"imgArt3"} name="Haza" role="Developer" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt4} social={"imgArt4"} name="Ecan" role="Developer" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt5} social={"imgArt5"} name="Esd" role="Developer" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt2} social={"imgArt1"} name="Hice" role="Coordinator" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card image={imgArt3} social={"imgArt1"} name="Thalia" role="Marketing" />
    ),
  },
];

export default cards;
