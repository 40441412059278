import React, { useEffect, useState } from "react";
import { roadMapDesktop, roadMapMobile } from "../Images";
import roadMapStyle from "./roadMapStyle.module.scss";

const Roadmap = () => {
  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  }
  const width = useWindowSize();
  return (
    <div className={roadMapStyle["container"]} id="roadmap">
      <div className={roadMapStyle.header}>
        <h2>ROADMAP</h2>
      </div>
      <section>
        <div className={roadMapStyle["backgroundContainer"]}>
          <div className={roadMapStyle["backgroundImg"]}>
            {width > 1024 ? (
              <img src={roadMapDesktop} alt="" />
            ) : (
              <img src={roadMapMobile} alt="" />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Roadmap;
