import { dedewLogo2 } from "../Images";
import { Nav, Container, Navbar, NavDropdown } from "react-bootstrap";
import "./headerStyle.scss";
import { useState, useEffect } from "react";
function Header() {
  const [showHeader, setShowHeader] = useState(false);
  const [color, setColor] = useState("#191819");
  const [opacity, setOpacity] =useState(1);


  useEffect(()=>{
    function handleScroll() {
    const scrollPosition = window.pageYOffset;
    const headerHeight = document.querySelector('header').offsetHeight;
    const maxScroll = headerHeight * 2;
    const opacity = Math.max(0, 1 - scrollPosition / maxScroll);
    setOpacity(opacity);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      };

  }, []);
  const headerStyle={
    opacity: opacity,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowHeader(true);
    }, 2040);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 200) {
        setColor("rgb(28 27 28 / 91%)");
      } else {
        setColor("#191819");
      }
    };
    window.addEventListener("scroll", changeColor);
  }, []);

  return (
    
      <header>
              <Navbar expand="lg" className="navbar-boot" fixed="top" style={{ backgroundColor: `${color}` , margin: 0}}>
        <Container fluid>
          <Navbar.Brand href="#">
            {/* <img src={dedewLogo2} alt="dedew logo" /> */}
          </Navbar.Brand>
        </Container>
      </Navbar>
      {showHeader && (
        <Navbar expand="lg" className="navbar-boot" fixed="top" style={{ backgroundColor: `${color}` ,  margin: 0}}>
          <Container fluid>
            <Navbar.Brand href="#">
              <img src={dedewLogo2} alt="dedew logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i className="fa-solid fa-bars text-light"></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll ">
              <Nav className="d-flex w-100 justify-content-center gap-1 ">
                <Nav.Link href="#blog">Blog</Nav.Link>
                <NavDropdown title="Project Details">
                  <NavDropdown.Item style={{ width: "100%" }} href="#learn">
                    Learn{" "}
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item style={{ width: "100%" }} href="#art">
                    Art
                  </NavDropdown.Item> */}
                  <NavDropdown.Item style={{ width: "100%" }} href="#roadmap">
                    Roadmap
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="About Us">
                  <NavDropdown.Item href="#team">Team</NavDropdown.Item>
                  <NavDropdown.Item href="#partnership">
                    Partners
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="#sponsorship">
                    Sponsorship
                  </NavDropdown.Item> */}
                </NavDropdown>
                <Nav.Link href="#contact">Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      </header>
    
  );
}

export default Header;
