import React from "react";
import { discord, instagram, linkedin, mail,   twitter, youtube } from "../Images";
import contactStyle from "./contactStyle.module.scss";

const Contact = () => {
  return (
    <div className={contactStyle["container"]} id="contact">
      <header className="text-center pt-5 text-light ">
        <h2>CONTACT US</h2>
      </header>
      <div className={contactStyle["content"]}>
        <div className={contactStyle["links"]}> 
          <a
            href="https://www.youtube.com/"
            target="_blank"
            rel="noreferrer"
            title="Youtube"
          >
            <img src={youtube} alt="" />
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noreferrer"
            title="instagram"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.gmail.com/"
            target="_blank"
            rel="noreferrer"
            title="mail"
          >
            <img src={mail} alt="" />
          </a>
          <a
            href="https://www.twitter.com/"
            target="_blank"
            rel="noreferrer"
            title="twitter"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/"
            target="_blank"
            rel="noreferrer"
            title="linkedin"
          >
            <img src={linkedin} alt="" />
          </a>
          <a
            href="https://www.discord.com/"
            target="_blank"
            rel="noreferrer"
            title="discord"
          >
            <img src={discord} alt="" />
          </a> 
        </div>
        <p className={contactStyle["copyright"]}>
          Copyright DEDEW &#169;
          {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Contact;
