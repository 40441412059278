import React from "react";
import blogStyle from "./blogStyle.module.scss";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { blockchain, efficiency, hourglass, languages } from "../Images";
import intro from "../../assets/images/intro/dedewIntro.mp4";

const Blog = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const animationLeft = useAnimation();
  const animationRight = useAnimation();

  useEffect(() => {
    if (inView) {
      animationLeft.start({
        opacity: 1,
        y: 0, 
        transition: {
          type: "spring",
          duration: 1.6,
          bounce: 0.3,
        },
      });
      animationRight.start({
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          duration: 1.6,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animationLeft.start({
        opacity: 0,
        y: "-100vh",
      });
      animationRight.start({
        opacity: 0,
        y: "100vh",
      });
    }
    // console.log("use effecthook, inView =", inView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      <section className={blogStyle["container"]} ref={ref} id="blog">
        <header className="text-center pt-5 text-light">
          <h2 className="blogh">BLOG</h2>
        </header>
        <div className={blogStyle["blog-container"]}>
          <motion.div className={blogStyle["content-container"]}>
            <motion.div
              className={blogStyle["blog-left"]}
              animate={animationLeft}
            >
              <div className={blogStyle["circle_1"]}>
                <img src={languages} alt="" />
              </div>
              <div className={blogStyle["circle_2"]}>
                <img src={efficiency} alt="" />
              </div>
              <div className={blogStyle["circle_3"]}>
                <img src={hourglass} alt="" />
              </div>
            </motion.div>
            <motion.div
              animate={animationRight}
              className={blogStyle["blog-right"]}
            >
              <div className={blogStyle["circle-up"]}>
                <div className={blogStyle["circle-right"]}>
                  <img src={blockchain} alt="" />
                </div>
              </div>
              <div className={blogStyle["content"]}>
                <p>
                  Examination will start 14th June 20 March 2022 *discord and
                  slack channels members will enter exam for improving positions
                  03.20.22 each three months *our team announce to you we are
                  going to create one more nft collection for designers... 16
                  March 2022 *we will meet in north macedonia for having fun and
                  introducing *our partnership coltra will describe us !! ...
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>
      <section className={blogStyle["intro-container"]}>
        <div className={blogStyle["overlay"]}></div>
        <video
          src={intro}
          type="video/mp4"
          autoPlay
          loop
          muted
          controlsList={"nodownload"}
          controls={false}
        ></video>
      </section>
    </>
  );
};

export default Blog;
